<template>
  <div class="layout">
    <div class="navigation">
      <div class="navigation-logo">
        <el-image style="width: 5rem" :src="require('@/static/images/member/huochuan.png')" fit="cover"></el-image>
        <div class="navigation-title">{{ $t('rich.title') }}</div>
      </div>
      <el-menu
          v-loading="loading"
          :default-active="activeMenu"
          active-text-color="#194D8F"
          router
          class="el-menu-vertical-demo">
        <el-menu-item :index="item.index" v-for="item in menuItems" :key="item.id">
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="operate" v-if="menuItems.length">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['help']),
    activeMenu() {
      const route = this.$route
      const { path } = route
      return path
    },
    menuItems() {
      return this.help.map(item => {
        return {
          id: item.Id,
          title: item.Title,
          index: `/help/rich?id=${item.Id}`
        }
      })
    }
  },
  components: {
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      setTimeout(() => {
        this.loading = true
        this.$store.dispatch('webSite/getShopMatters', 2).then(response => {
          this.$store.commit('webSite/SET_HELP_DATA', response)
          this.loading = false
        })
      }, 800)
    }
  }
}

</script>

<style scoped lang="scss">
.layout {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  .navigation {
    width: 21%;
    height: auto;
    .navigation-logo {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: #fff;
      padding: 1.8rem 1.2rem;
      border: 0.1rem solid rgba(237,237,237,1);
      .navigation-title {
        font-size: 2.4rem;
        font-weight: bold;
        color: #194D8F;
      }
    }
  }
  .operate {
    background-color: #fff;
    width: 77%;
    min-height: calc(100vh - 39rem);
    box-sizing: border-box;
  }
}
</style>
